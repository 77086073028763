/* eslint-disable jsx-a11y/alt-text */
import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {useTranslation} from 'react-i18next';
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

// styles
import defaultPic from "@assets/default.webp";
import styled from "@emotion/styled";
import { StyleConstants } from "@styles/variables";
import { Heading } from "@components/Heading";
import {LazyLoadImage} from "react-lazy-load-image-component";

export const LeakCard = forwardRef(
  ({ title, description, pictureUrl, id, currentPage, views, ...props }, ref) => {

    const {t} = useTranslation();

    const [loaded, setLoaded] = useState(false);
    const cardRef = useRef(null);

    useImperativeHandle(ref, () => ({ id, cardRef }));

    return (
      <CardWrapper {...props} ref={cardRef} to={`/${currentPage}/details/${id}`}>
        <Card>
          <LazyLoadImage loading={'lazy'}
            onLoad={() => setLoaded(true)}
            src={pictureUrl}
            className={!loaded ? "loading" : null}
          />
          <CardContent>
            <Heading>{title}</Heading>
            <div>{t('details.views')}: <span>{+views}</span></div>
            <Description>
              <ReactMarkdown children={description} />
            </Description>
          </CardContent>
        </Card>
      </CardWrapper>
    );
  }
);

export const CardWrapper = styled(Link)`
  text-decoration: none;
  color: ${StyleConstants.COLORS.WHITE};
  border-radius: 10px;
  filter: drop-shadow(0px 0px 0px ${StyleConstants.COLORS.RED_OPACITY});

  &:hover {
    cursor: pointer;
    transition: filter ease-in-out 0.15s;
    filter: drop-shadow(0px 0px 10px ${StyleConstants.COLORS.RED_OPACITY});
  }
`;

export const Card = styled.article`
  background: linear-gradient(320.62deg, #292929 -0.04%, #191919 71.54%);
  width: 350px;
  border-radius: 10px;

  img {
    width: inherit;
    object-fit: cover;
    height: 250px;
    border-radius: 10px 10px 0 0;

    &.loading {
      background: url("../../assets/default.webp");
    }
  }

  @media screen and (max-width: 400px) {
    .card {
      width: 275px;
    }
  }
`;

export const CardContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;

  h1 {
    height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.25rem;
    text-align: left;
    color: ${StyleConstants.COLORS.RED};
    padding: 0;
  }
`;

export const Description = styled.div`
  height: 150px;
  overflow: hidden;

  p {
    margin-top: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.25rem;
    font-size: 0.8rem;
  }
`;
