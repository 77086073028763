import { useEffect, useLayoutEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import defaultPic from "@assets/default.webp";
import { baseUrl } from "@utils/constants";
import { setPage, setLoading } from "@slices/postsSlice";
import { currentLanguage } from "@locales/i18n";
import { Header } from "@components/Header/Header";
import { Wrapper } from "@components/Wrapper";
import { CustomPagination } from "@components/CustomPagination/CustomPagination";
import { Spiner } from "@components/Spinner";
import { ErrorMessage } from "@components/ErrorMessage";
import { LeakCard } from "@components/LeaksCard/LeakCard";
import { fetchPosts } from "@slices/postsSlice";
import { Heading } from "@components/Heading";
import { Helmet } from "react-helmet-async";
import { sizes } from "@styles/media";

export const Slivi = () => {
  const location = useLocation();
  const scrollLeakId = location.state || null;
  const cardRefs = useRef([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { page } = useParams();
  const dispatch = useDispatch();
  const currentLng = currentLanguage();
  const { totalPages, posts, status, error, currentPage, locale } = useSelector(
    (state) => state.posts
  );

  const request = () => {
    dispatch(setLoading());
    dispatch(fetchPosts(page));
    dispatch(setPage(page));
  };

  useEffect(() => {
    cardRefs.current.forEach((cardRef) => {
      if(cardRef.id === +scrollLeakId){
        cardRef.cardRef?.current?.scrollIntoView()
      }
    });
  }, [scrollLeakId]);

  useLayoutEffect(() => {
    if (locale !== currentLng || page !== currentPage || !posts.length) {
      request();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, i18n.language]);

  const pageChange = (selectedPage) => {
    navigate(`/leaks/${Number(selectedPage.selected) + 1}`);
  };

  return (
    <>
      <Helmet>
        <title itemProp="name" lang={currentLng}>
          Радикальня | {t("pages.leaks")}
        </title>
        <meta property="og:type" content="about" />
        <meta property="og:image" content={baseUrl + defaultPic} />
        <meta property="og:image:secure_url" content={baseUrl + defaultPic} />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content="640" />
        <meta property="og:image:height" content="640" />
        <meta property="og:image:alt" content="Radical logo" />
      </Helmet>

      <Header />
      <PlumsWrapper>
        {!!posts.length && (
          <>
            <ContentWrapper>
              <PageHeading>{t("drops")}</PageHeading>
              <PlumsCards>
                {posts.map((sliv, idx) => {
                  const {
                    attributes: { title, description, cover, views },
                    id,
                  } = sliv;

                  //В идеале - средний размер картинки
                  const imgUrl = cover.data.attributes.formats?.medium?.url || cover.data.attributes.url;

                  return (
                    <LeakCard
                      ref={(el) => (cardRefs.current[idx - 1] = el)}
                      key={idx}
                      title={title}
                      description={description}
                      views={views}
                      pictureUrl={
                        cover.data
                          ? baseUrl + imgUrl
                          : defaultPic
                      }
                      id={id}
                      currentPage={page}
                    />
                  );
                })}
              </PlumsCards>
            </ContentWrapper>
            <CustomPagination
              page={page}
              totalPages={totalPages}
              onPageChange={pageChange}
            />
          </>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {status === "loading" && <Spiner />}
      </PlumsWrapper>
    </>
  );
};

const PlumsCards = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  margin-bottom: 5rem;
  gap: 4rem;
  flex-wrap: wrap;
`;

const PageHeading = styled(Heading)`
  padding-top: 2rem;
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const PlumsWrapper = styled(Wrapper)`
  max-width: ${sizes.large}px;
  margin: 0 auto;
`;
